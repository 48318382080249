@font-face {
  font-display: swap;
  font-family: "Apercu Optimized";
  font-weight: 400;
  src: url("../../../assets/fonts/apercu-regular.woff2") format("woff2"),
    url("../../../assets/fonts/apercu-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Apercu";
  font-style: normal;
  font-weight: 400;
  src: url("../../../assets/fonts/apercu-regular.woff2") format("woff2"),
    url("../../../assets/fonts/apercu-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Apercu";
  font-style: italic;
  font-weight: 400;
  src: url("../../../assets/fonts/apercu-italic.woff2") format("woff2"),
    url("../../../assets/fonts/apercu-italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Apercu";
  font-style: normal;
  font-weight: 700;
  src: url("../../../assets/fonts/apercu-bold.woff2") format("woff2"),
    url("../../../assets/fonts/apercu-bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Apercu";
  font-style: italic;
  font-weight: 700;
  src: url("../../../assets/fonts/apercu-bold-italic.woff2") format("woff2"),
    url("../../../assets/fonts/apercu-bold-italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular B";
  font-style: normal;
  font-weight: 400;
  src: url("../../../assets/fonts/EuclidCircularB-Regular-WebS.woff2") format("woff2"),
    url("../../../assets/fonts/EuclidCircularB-Regular-WebS.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular B";
  font-style: normal;
  font-weight: 700;
  src: url("../../../assets/fonts/EuclidCircularB-Bold-WebS.woff2") format("woff2"),
    url("../../../assets/fonts/EuclidCircularB-Bold-WebS.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular B";
  font-style: italic;
  font-weight: 700;
  src: url("../../../assets/fonts/EuclidCircularB-BoldItalic-WebS.woff2") format("woff2"),
    url("../../../assets/fonts/EuclidCircularB-BoldItalic-WebS.woff") format("woff");
}
