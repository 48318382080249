/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// Fonts
@import "./theme/fonts.scss";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

// Theme customization
@import "theme/theme";

// Material Icons
@import "material-icons/iconfont/material-icons.css";
// @import "@angular/material/theming";
@import "@angular/cdk/overlay-prebuilt.css";
@import "theme/material-custom-theme.scss";

// PIP Theme
@import "./theme/pip.scss";

// Material Custom Components
@import "./theme/material-custom-component.scss";

// Guided Tour
@import "./theme/guided-tour.scss";
