@use "@angular/material" as mat;
// @import "@angular/material/theming";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

/*
*'--primary': '#394160' from ../theme/default-theme.ts
*/
$md-custom-primary: (
  50: #e8edf3,
  100: #d1dbe7,
  200: #bbc9dc,
  300: #a4b7d0,
  400: #8da5c4,
  500: #000e52,
  600: #000b41,
  700: #000830,
  800: #00051f,
  900: #00020e,
  A100: #526fff,
  A200: #002bfc,
  A400: #001ca7,
  A700: #00051f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/*
*'--secondary': '#c8c618' from ../theme/default-theme.ts
 */
$md-custom-secondary: (
  50: #bbffd6,
  100: #88ffb7,
  200: #66ffa2,
  300: #44ff8d,
  400: #22ff79,
  500: #00ff64,
  600: #00cc50,
  700: #00993c,
  800: #006628,
  900: #003314,
  A100: #ddffea,
  A200: #77ffac,
  A400: #22ff79,
  A700: #00bb49,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/*
  #f07746
 */
$md-custom-warn: (
  50: #ffffff,
  100: #fcd9ae,
  200: #fbc98d,
  300: #faba6c,
  400: #f9aa4b,
  500: #f89b2a,
  600: #f78c09,
  700: #d77907,
  800: #b66706,
  900: #955405,
  A100: #ffffff,
  A200: #ffded0,
  A400: #ff956a,
  A700: #f68659,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$font-family: 'Apercu", "Euclid Circular B';
$font-size: 18px;
$line-height: var(--bs-body-line-height);

$gematik-base-level: mat.define-typography-level(
  $font-family: $font-family,
  $font-size: $font-size,
  $line-height: $line-height,
  $letter-spacing: inherit,
);

$gematik-headline-level: mat.define-typography-level(
  $font-family: $font-family,
  $font-size: 1.3rem,
  $line-height: $line-height,
  $letter-spacing: inherit,
);

$gematik-subtitle-level: mat.define-typography-level(
  $font-family: $font-family,
  $font-size: 1rem,
  $line-height: $line-height,
  $letter-spacing: inherit,
);

$gematik-typography: mat.define-typography-config(
  $headline-6: $gematik-headline-level,
  $subtitle-1: $gematik-subtitle-level,
  $subtitle-2: $gematik-subtitle-level,
  $body-1: $gematik-base-level,
  $body-2: $gematik-base-level,
);

$custom-theme-primary: mat.define-palette($md-custom-primary);
$custom-theme-accent: mat.define-palette($md-custom-secondary);
$custom-theme-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    density: 0,
    typography: $gematik-typography,
  )
);

@include mat.all-component-themes($custom-theme);
