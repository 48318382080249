$md-chip-height: 32px;
$md-chip-color: #e0e0e0;

.md-chip {
  display: inline-block;
  background: $md-chip-color;
  padding: 0 12px;
  border-radius: 32px;
  font-size: 13px;
  &.md-chip-hover:hover {
    background: #ccc;
  }
}

.md-chip-clickable {
  cursor: pointer;
}

.md-chip,
.md-chip-icon {
  height: $md-chip-height;
  line-height: $md-chip-height;
}

.md-chip-remove {
  display: inline-block;
  background: #aaa;
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0 -4px 0 4px;
  cursor: pointer;
  font: inherit;
  line-height: 20px;
  &:after {
    color: $md-chip-color;
    content: "x";
  }
  &:hover {
    background: #999;
  }
  &:active {
    background: #777;
  }
}

.md-chips {
  padding: 12px 0;
  .md-chip {
    margin: 0 5px 3px 0;
  }
}

a {
  color: var(--gematik-blue) !important;
}
