$tour-next-text-color: var(--gematik-neon) !important;
$tour-next-button-color: var(--on-secondary) !important;
$tour-next-button-hover: var(--on-secondary) !important;
$tour-back-button-color: var(--on-secondary) !important;

.tour-buttons {
  button {
    padding: 5px !important;
    border-radius: 3px !important;
  }
  .back-button {
    margin-right: 10px !important;
  }
}

.tour-block {
  border-radius: 5px;
}

.guided-tour-spotlight-overlay {
  border-radius: 2px;
}

@import "ngx-guided-tour/scss/guided-tour-base-theme.scss";
