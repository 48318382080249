/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

:root {
  --font-family: "Apercu", "Euclid Circular B";
  --font-size: 18px;
  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #868e96;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black: #000;

  --blue: #0073dd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --gematik-neon: #00ff64;
  --gematik-blue: #000e52;
  --gematik-light-blue: #ebeff5;
  --gematik-cyan: #4affff;
  --gematik-red: #ff0000;
  --gematik-red-medium: #ed6c71;
  --gematik-warn: #efec01;
  --gematik-grey: #f5f7fa;
  --gematik-dark-grey: #595959;
  --gematik-light-grey: #f9f9f9;
  --gematik-error: #ff0000;
  --on-primary: var(--gematik-blue);
  --on-secondary: var(--gematik-blue);
}

// $white: #fff;
// $gray-100: #f8f9fa;
// $gray-200: #e9ecef;
// $gray-300: #dee2e6;
// $gray-400: #ced4da;
// $gray-500: #adb5bd;
// $gray-600: #868e96;
// $gray-700: #495057;
// $gray-800: #343a40;
// $gray-900: #212529;
// $black: #000;

// $blue: #0073dd;
// $indigo: #6610f2;
// $purple: #6f42c1;
// $pink: #e83e8c;
// $red: #dc3545;
// $orange: #fd7e14;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #20c997;
// $cyan: #17a2b8;
// $gematik-neon: #00ff64;
// $gematik-blue: #000e52;
// $gematik-cyan: #4affff;
// $gematik-red: #ff0000;
// $gematik-warn: #efec01;
// $gematik-grey: #eff0f3;
// $gematik-error: #ff0000;
// $on-primary: $gematik-blue;
// $on-secondary: $gematik-blue;

// $theme-colors: (
//   primary: $gematik-blue,
//   secondary: $gray-600,
//   success: $green,
//   info: $cyan,
//   warning: $yellow,
//   danger: $red,
//   light: $gray-100,
//   dark: $gray-800,
//   background: $white,
//   hover: #7f7f7f,
//   hover-underline: $gematik-neon,
//   on-primary: $gematik-blue,
//   on-secondary: $gematik-blue,
//   warn: $gematik-red,
//   error: $gematik-red,
//   validation-error: $gematik-red,
//   gematik-neon: $gematik-neon,
//   gematikBlue: $gematik-blue,
//   sidenav-border: $gematik-blue,
//   header-background: $gematik-grey,
//   gematik-grey: $gematik-grey,
// );

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
